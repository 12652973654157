import { isServer } from "@tanstack/react-query";
import FathomTracker from "./fathom";
import { Tracker } from "./types";
import UmamiTracker from "./umami";

const dataAttributeName = "data-tracking-event";

export const umami = new UmamiTracker();
umami.init();

export const fathom = new FathomTracker();
fathom.init();

const trackers: Tracker[] = [umami, fathom];

export const initTracking = () => {
  if (isServer) {
    return;
  }

  window.document.body.addEventListener("click", (event) => {
    let element = event.target;

    while (element instanceof Element) {
      if (element.hasAttribute(dataAttributeName)) {
        const eventName = element.getAttribute(dataAttributeName);
        if (eventName) {
          const props: Record<string, string> = {};
          for (let i = 0; i < element.attributes.length; i++) {
            const attr = element.attributes[i];

            if (attr && attr.name.startsWith(dataAttributeName)) {
              props[attr.name.replace(dataAttributeName + "-", "")] =
                attr.value;
            }
          }

          trackEvent(eventName, props);
        }
      }
      element = element.parentElement;
    }
  });
};

export const isTrackingEnabled = () => {
  return trackers.every((tracker) => tracker.isEnabled());
};

export const trackEvent = (
  eventName: string,
  props?: Record<string, string | number | boolean | undefined>,
) => {
  trackers.forEach((tracker) => tracker.trackEvent(eventName, props));
};

export const disableTracking = () => {
  trackers.forEach((tracker) => tracker.disable());
};
